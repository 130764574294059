<template>
    <mnSup mostrar="true" />
    <main id="exp">
        <div class="parrafo">
        <h1>PROGRAMA EXPLORA</h1>
        <br/>
        <p>
            El programa Explora tiene como objetivo promover la socialización del conocimiento dentro
            de la comunidad educativa, contribuyendo al desarrollo de competencias en las distintas
            áreas del conocimiento, la tecnología y la innovación. Para ello, su labor está enfocada en
            promover el desarrollo profesional docente y la formación de comunidades educativas; 
            contribuir al desarrollo de recursos y experiencias educativas; y promover la investigación
            e innovación en las comunidades escolares, surgiendo estas temáticas desde las propias 
            inquietudes de las y los estudiantes a través de un trabajo en conjunto con sus pares y 
            acompañados por docentes y, generalmente, asesores/as de investigación o innovación.
        </p>
        </div>
        <div class="grilla">

            <div class="boxBtn">
                <img src="/assets/img/info-ico-explora.svg" alt="">
                <a href="https://www.explora.cl/" target="_blank">WWW.EXPLORA.CL</a>
            </div>
            <div class="boxBtn">
                <img src="/assets/img/info-ico-evaluaciones.svg" alt="">
                <button @click="goBuscador()">EVALUACIONES</button>
            </div>
        </div>
    </main>

    <footerLogo />
</template>
<script>

import footerLogo from '@/components/footerLogo.vue'
import mnSup from '@/components/mnSup.vue'
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'Explora',
    data() {
        return {

        }
    },
    components: {
        mnSup,
        footerLogo
    },
    created() {
        document.title = 'PROGRAMA EXPLORA - XXI Congreso Nacional Explora'
    },
    methods: {
        ...mapMutations(['showModal']),
        goBuscador() {
            window.location.href = `/login`;
        }
    }
}
</script>

<style lang="scss" scoped >
main#exp {
    display: grid;
    justify-content: center;
    grid-template-areas: "a"
                         "b";
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 70px;
    padding-left: 20%;
    padding-right: 20%;
    @media(max-width:1100px){
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 70px;
    }

}
.parrafo{
    display: grid;
    grid-area: a;
    grid-template-columns: 100%;
    line-height: 1.6;
    @media(max-width:1100px){
        display: grid;
        grid-template-columns: 100%;
    }
}
.grilla {
        display: grid;
        grid-area: b;
        grid-template-columns: 1fr 1fr;  
        justify-content: center;
        justify-items: center;
        margin-top: 10%;
        @media(max-width:1100px){
            display: grid;
            grid-template-columns: 100%;
            gap:20px;
            margin-bottom: 50px;
            justify-content: center;
            align-items: center;
        }

        .boxBtn {
            width: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;
            max-width: 240px;
            @media(max-width:1100px){
                margin: 0;
            }

            img {
                width: 70%;
                height: auto;

                &.imgH {
                    width: auto;
                    height: 120px;
                }
            }

            button,
            a {
                background-color: #000;
                color: #fff;
                padding: 15px 20px;
                border-radius: 50px;
                min-height: 60px;
                min-width: 200px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-family: 'Museo', sans-serif;
                font-size: .9rem;

                &:hover {
                    background-color: rgb(49, 49, 49);
                }
            }
        }


        }

</style>